<template>
    <el-container class="page-container">
        <el-main class="page-main">
          <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
            <el-row :gutter="20">
              <el-col :xs="12" :sm="12" :md="8" :lg="6">
                <el-input v-model="query.name" clearable placeholder="物料名称" class="inner-input">
                </el-input>
              </el-col>
              <el-col :xs="12" :sm="12" :md="8" :lg="6">
                <el-form-item label-width="0">
                  <el-button type="primary" @click="handleSearch">
                    <i class="cw-icon cw-icon-sousuo"></i>搜索
                  </el-button>
                  <el-button type="primary" plain @click="clearSearch">
                    <i class="cw-icon cw-icon-zhongzhi"></i>重置
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
            <el-row class="row-btn-group">
                <el-button type="primary" @click="handleAdd('新增')">
                    <i class="cw-icon cw-icon-tianjia"></i>新增物料
                </el-button>
                <el-button type="primary" @click="doBuyMany('新增')">
                  <i class="cw-icon cw-icon-tianjia"></i>批量入库
                </el-button>
            </el-row>
            <el-table
                    :data="tableData"
                    style="width: 100%"
                    :row-class-name="tableRowClass"
                    @current-change="toggleRowExpansion"
            >
                <el-table-column
                    label="物料名称"
                    prop="name">
                </el-table-column>
                <el-table-column
                        label="类型"
                        prop="categoryName">
                </el-table-column>
                <el-table-column
                    label="库存"
                    prop="store">
                </el-table-column>
                <el-table-column
                    label="库存阈值"
                    prop="store_warn">
                </el-table-column>
                <el-table-column
                    label="物料单位"
                    prop="unit">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="300px"
                >
                  <template slot-scope="scope">
                    <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>修改
                    </el-button>
                    <el-button size="mini" type="success" @click="doDelete(scope.row)" plain>删除
                    </el-button>
                    <el-button size="mini" type="success" @click="doBuy(scope.row)" plain>入库
                    </el-button>
                    <el-button size="mini" type="success" @click="selectMaterialAndOrder(scope.row)" plain>消耗记录
                    </el-button>
                  </template>
                </el-table-column>
            </el-table>
            <br>
        </el-main>
        <!--//编辑物料类型-->

        <el-dialog
                width="480px"
                :title="title"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="visibleFormDialog"
                :before-close="handleClose">
            <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="85px"
                     class="adsdia">
                <el-form-item prop="categoryId" label="物料类型">
                    <el-select v-model="formData.categoryId" placeholder="物料类型" style="width: 100%;">
                        <el-option v-for="(item,index) in posData" :key="index" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="name" label="物料名称">
                    <el-input maxlength="100" placeholder="物料名称"
                              v-model="formData.name"
                              auto-complete="off" tabindex="5" type="text"></el-input>
                </el-form-item>
                <el-form-item prop="store_warn" label="库存阈值">
                  <el-input maxlength="100" placeholder="库存阈值"
                            v-model="formData.store_warn"
                            auto-complete="off" tabindex="5" type="text"></el-input>
                </el-form-item>
                <el-form-item prop="unit" label="物料单位">
                    <el-select v-model="formData.unit" placeholder="请选择" style="width: 100%">
                      <el-option
                          v-for="item in unitOptions"
                          :key="item.id"
                          :label="item.unitName"
                          :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="0">
                    <el-button type="primary" @click="doEdit">确 定</el-button>
                    <el-button @click="handleClose">取 消</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>

        <el-dialog
          width="480px"
          title="库存消耗"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="usedList"
          fullscreen
          :before-close="handleClose">
          <usedList :materialId="materialId"></usedList>

      </el-dialog>
<!--        批量购买-->
        <el-dialog title="购买物料" :visible.sync="buyFormDialog" :fullscreen="true">
          <el-form :model="sendData" ref="configForm">
            <el-table
                ref="multipleTable"
                :data="sendData.config"
                tooltip-effect="dark"
                style="width: 100%"
            >
              <el-table-column
                  label="物料类型"
                  prop="categoryName"
                  show-overflow-tooltip
              >

              </el-table-column>
              <el-table-column
                  label="名称"
                  prop="name"
                  show-overflow-tooltip
              >

              </el-table-column>

              <el-table-column
                  prop="num"
                  label="数量"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                      :prop="'config.'+scope.$index+'.num'"
                      :rules="[
                          {pattern: /^(-)*(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/,message: '最多只能输入2位小数',trigger: ['blur','change']}
                      ]"
                  >
                    <el-input v-model="scope.row.num" placeholder="数量"></el-input>
                  </el-form-item>
<!--                  <el-input maxlength="100" placeholder="数量"-->
<!--                            v-model="scope.row.num"-->
<!--                            min="0"-->
<!--                            auto-complete="off" tabindex="5" type="number"></el-input>-->
                </template>
              </el-table-column>
              <el-table-column
                  prop="unit"
                  label="单位"
                  show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </el-form>
          <div class="flex flex-direction-row-reverse btn-group" style="margin-top: 20px">
            <el-button type="primary" @click="doAdd">确 定</el-button>
            <el-button @click="closeModul">取 消</el-button>
          </div>
        </el-dialog>
    </el-container>

</template>

<script>
import {mapGetters} from 'vuex'
import {
  getFoodMaterialList,
  materialAdd,
  materialEdit,
  materialdelete,
  addFoodShoppingList
} from '../../api/foodManager'
import moment from 'moment'
import request from "@/utils/request";
import usedList from '@/views/foodsManage/components/used'
export default {
    name: "foodMaterialManage",
    components:{usedList},
    data() {
        return {
            tableData:[],
            query: {
                name: '',
            },
            visibleFormDialog: false,
            formData: {
                id: '',
                categoryId: '',
                name: '',
                store_warn:'',
                unit: ''
            },
            title: '新增物料',
            formRules: {
                categoryId: [
                    {required: true, message: '物料类型', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '物料名称', trigger: 'blur'}
                ],
                store_warn: [
                  {required: true, message: '库存阈值', trigger: 'blur'}
                ],
                unit: [
                    {required: true, message: '物料单位', trigger: 'blur'}
                ],
            },
            selectData: [],
            posData:[],
            sendData:{
              config:[]
            },
            getRowKeys(row) {
                return row.category_id;
            },
            expands: [],
            unitOptions:[],

            usedList:false,
            materialId:null,

          buyFormDialog:false

        }
    },
    computed: {
        ...mapGetters([
            'userInfo'
        ]),
    },
    methods: {
        tableRowClass({row,rowIndex}){
           if(row.store<row.store_warn){
             return 'storeWarn'
           }
        },
        handleSearch() {
          this.getFoodMaterialList()
        },
        clearSearch() {
          this.query = {
            name: null,
          },
          this.handleSearch()
        },
        handleClose() {
            this.formData = {
                id: '',
                categoryId: '',
                store_warn:"",
                name: '',
                unit: ''
            };
            if(this.$refs.editForm)
            this.$refs.editForm.resetFields();
            this.visibleFormDialog = false;

            this.usedList=false;

        },
        handleAdd() {
            this.title = '新增物料';
            this.visibleFormDialog = true;
        },
        handleEdit(row) {
            console.log(428, row);
            row.categoryId = row.category_id;
            row.id = row.materialId;
            this.title = '修改物料';
            // this.formData = row;
            this.formData.id=row.id;
            this.formData.categoryId=row.categoryId;
            this.formData.name=row.name;
            this.formData.unit=row.unitId;
            this.formData.store_warn=row.store_warn;
            this.visibleFormDialog = true
        },
        toggleRowExpansion(id) {
            this.expands = [];
            this.expands.push(id);
        },
        //修改
        async doEdit() {
            this.$refs['editForm'].validate(async (valid) => {
                if (valid) {
                    // console.log(247,this.formData.id)
                    try {
                        this.formData.id ? await materialEdit(this.formData) : await materialAdd(this.formData);
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.visibleFormDialog = false;
                        this.$refs['editForm'].resetFields();
                        // this.toggleRowExpansion(this.formData.category_id)
                        this.getFoodMaterialList();

                    } catch (e) {

                    }
                }
            })
        },
        //删除
        async doDelete(row) {
            console.log(308, row);
            this.$confirm('删除后无法恢复，确定删除' + '吗?', '确定', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    await materialdelete(row.materialId);
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.getFoodMaterialList()
                } catch (e) {
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        handleSelectionChange(val) {
            console.log(334, val);
            this.selectData = val;
        },
        closeModul() {
            this.visibleFormDialog = false;
            this.buyFormDialog = false;
            this.formData = {};
            this.getFoodMaterialList();
        },

        async getFoodMaterialList() {
            let query = Object.assign({}, this.query);
            let formData = {};
            for (let i in query) {
              if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
                formData[i] = query[i]
              }
            }
            // console.log(299,formData);
            let {data} = await getFoodMaterialList(formData);
            this.tableData=data.goodsMaterialList;
            this.sendData.config=data.goodsMaterialList;
            // data.goodsMaterialList = this.handleArr(data.goodsMaterialList);
            // console.log(533, data.goodsMaterialList);
            // this.posData = data.goodsMaterialList;
        },

        handleArr(arr) {
            var map = {},
                    dest = [];
            for (var i = 0; i < arr.length; i++) {
                var ai = arr[i];
                if (!map[ai.category_id]) {
                    dest.push({
                        category_id: ai.category_id,
                        categoryName: ai.categoryName,
                        data: [ai]
                    });
                    map[ai.category_id] = ai;
                } else {
                    for (var j = 0; j < dest.length; j++) {
                        var dj = dest[j];
                        if (dj.category_id == ai.category_id) {
                            dj.data.push(ai);
                            break;
                        }
                    }
                }
            }
            return dest;
            // console.log(dest);
        },
        // async selectMaterialCategoryList(){
        //   let {data} = await selectMaterialCategoryList();
        //   console.log(408,data)
        // },
        async getData() {
            try {
                let {data} = await request({
                    url: '/platform/food/unit/data',
                    method: 'post'
                })
                console.log(data)
                this.unitOptions = data;
            } catch (e) {

            }
        },
        selectMaterialAndOrder(row){
            this.usedList=true;
            this.materialId=row.materialId;
        },
        async doBuy(row){
          try {
              let {value} = await this.$prompt('请输入购买数量', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  inputPattern: /^[0-9]+(.[0-9]{0,2})?$/,
                  inputErrorMessage: '请输入购买数量'
              })
              console.log(value);
              let formData=[Object.assign({},{num:Number(value)},row)];
              await addFoodShoppingList({foodShoppingList:JSON.stringify(formData)});
              this.$message.success('购买成功');
              this.getFoodMaterialList();
          } catch (e) {
              console.error(e)
              this.$message({
                  type: 'info',
                  message: '取消'
              });
          }
        },
        async getMaterial() {
          try {
            let {data} = await request({
              url: '/platform/food/material/goods/data',
              method: 'post'
            })
            console.log(data)
            this.posData = data.list;
          } catch (e) {

          }
        },
        doBuyMany(){
          // this.selectMaterialCategoryList();
          this.buyFormDialog = true;
        },
        async doAdd() {
          this.$refs.configForm.validate(async (valid) => {
            if(valid){
              try {
                let formData=this.sendData.config.filter(curr=>curr.num);
                if(formData.length>0){
                  await addFoodShoppingList({foodShoppingList:JSON.stringify(formData)});
                  this.$message({
                    type: 'success',
                    message: '操作成功!'
                  });
                  this.getFoodMaterialList();
                }
                this.buyFormDialog = false;
              }catch (e) {

              }
            }
          });

        },
    },
    async mounted() {
        await this.getData()
        this.getFoodMaterialList();
        this.getMaterial();

    }
}
</script>

<style lang="less">
.adsdia {
    .el-upload {
        height: 100px;
        width: 100px !important;
        line-height: 100px;
    }
}

.btn-group {
    button {
        margin-left: 20px;
    }

}

.el-input--prefix .el-input__inner {
    padding-left: 46px;
}

.el-input__prefix {
    left: 15px;
    color: #303133;

    i {
        font-style: normal;
    }
}

.icon-cursor {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
        color: #348EED;
    }
}

.avatar-uploader {
    .el-upload {
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 72px;
        display: block;

        &:hover {
            border-color: #409EFF;
        }
    }

    .cw-icon-tupian {
        font-size: 24px;
        color: #c0c4cc;
        width: 72px;
        height: 72px;
        line-height: 72px;
        text-align: center;
    }

    .avatar {
        width: 72px;
        height: 72px;
        display: block;
    }
}
.storeWarn{
  .cell{
    color: #f56c6c;
  }
}
</style>
