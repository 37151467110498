import request from '../utils/request'
export function orderScales(data){
    return request({
        url:'/platform/food/order/order-scales',
        method:'post',
        data
    })
}


//查询

export function foodTypeData(data) {
    return request({
        url:'/platform/food/type/data',
        method:'post',
        data
    })
}
//查看消耗

export function selectMaterialAndOrder(data) {
    return request({
        url:'/platform/food/material/selectMaterialAndOrder',
        method:'post',
        data
    })
}

export function foodTypeEdit(id) {
    return request({
        url:`/platform/food/type/edit/${id}`,
        method:'post'
    })
}


export function foodTypeDelte(id) {
    return request({
        url:`/platform/food/type/delete/${id}`,
        method:'post'
    })
}
export function foodTypeDeltes(data) {
    return request({
        // url:`/platform/food/type/delete`,
        url:'/platform/food/goods/delete',
        method:'post',
        data
    })
}
export function foodTypeAddDo(data) {
    return request({
        url:`/platform/food/type/addDo`,
        method:'post',
        data
    })
}
export function foodTypeEditDo(data) {
    return request({
        url:`/platform/food/type/editDo`,
        method:'post',
        data
    })
}




export function goodsData(data) {
    return request({
        url:'/platform/food/goods/data',
        method:'post',
        data
    })
}
export function goodsAddDo(materialIds,data) {
    return request({
        url:`/platform/food/goods/addDo?materialIds=${materialIds}`,
        method:'post',
        data
    })
}
export function goodsEditDo(materialIds,data) {
    return request({
        url:`/platform/food/goods/editDo?materialIds=${materialIds}`,
        method:'post',
        data
    })
}

export function getGoodsEditDo(id) {
    return request({
        url:`platform/food/goods/edit/${id}`,
        method:'post',
    })
}

export function getFoodMaterialList(data) {
    return request({
        url:`platform/food/material/selectFoodMaterialList`,
        method:'post',
        data
    })
}



//菜品销量统计：

export function statisFoodsales(data) {
    return request({
        url:`platform/data/statis/foodsales`,
        method:'post',
        data
    })
}

//物料统计：
export function statisMaterialspends(data) {
    return request({
        url:`platform/data/statis/materialspends`,
        method:'post',
        data
    })
}


//资金总览：

export function statisTotalsales(data) {
    return request({
        url:`platform/data/statis/totalsales`,
        method:'post',
        data
    })
}

//资金明细：
export function statisSaledetails(data) {
    return request({
        url:`platform/data/statis/saledetails`,
        method:'post',
        data
    })
}
//网吧订单统计
export function statisBarsales(data) {
    return request({
        url:`platform/data/statis/barsales`,
        method:'post',
        data
    })
}
//网吧订单统计
export function statisSpecialsales(data) {
    return request({
        url:`platform/data/statis/specialsales`,
        method:'post',
        data
    })
}
//网吧订单统计
export function allStaffSales(data) {
    return request({
        url:`platform/data/statis/allStaffSales`,
        method:'post',
        data
    })
}
/////////////////////////////////////////////////////////////////
//物料管理列表

export function selectFoodShoppingList(data) {
    return request({
        url:`platform/food/shopping/order/data`,
        method:'get',
        data
    })
}
//添加一个清单
export function addFoodShoppingList(data) {
    return request({
        url:`platform/food/shopping/addFoodShoppingList`,
        method:'post',
        data
    })
}
//新增物料
export function materialAdd(data) {
    return request({
        url:`platform/food/material/addDo`,
        method:'get',
        data
    })
}
//修改物料
export function materialEdit(data) {
    return request({
        url:`platform/food/material/editDo`,
        method:'post',
        data
    })
}
//删除物料
export function materialdelete(data) {
    return request({
        url:`platform/food/material/delete/`+data,
        method:'DELETE',
    })
}
//查询物料类型
export function selectMaterialCategoryList() {
    return request({
        url:`platform/food/material/goods/selectMaterialCategoryList`,
        method:'get',
    })
}
//修改购物清单列表
export function updateFoodShoppingList(data) {
    return request({
        url:`platform/food/shopping/updateFoodShoppingList`,
        method:'PUT',
        data
    })
}
//规格编辑

export function skuEditDo(data) {
    return request({
        url:`platform/food/sku/editDo`,
        method:'post',
        data
    })
}
export function deleteSku(id) {
    return request({
        url:`platform/food/sku/delete/${id}`,
        method:'post'
    })
}

//标签分页查询
export function getTagsData(data) {
    return request({
        url:`platform/food/tags/data`,
        method:'post',
        data
    })
}


export function tagsDataNoPage(data) {
    return request({
        url:`platform/food/tags/dataNoPage`,
        method:'post',
        data
    })
}


export function tagsAddDo(data) {
    return request({
        url:`platform/food/tags/addDo`,
        method:'post',
        data
    })
}




export function tagsEditDo(data) {
    return request({
        url:`platform/food/tags/editDo`,
        method:'post',
        data
    })
}


export function deleteTags(id) {
    return request({
        url:`platform/food/tags/delete/${id}`,
        method:'post'
    })
}

export function noteDo(data) {
    return request({
        url:`platform/food/shopping/order/noteDo`,
        method:'post',
        data
    })
}


export function dofinish(data) {
    return request({
        url:`platform/food/goods/dofinish`,
        method:'post',
        data
    })
}

//查询盘点信息
export function foodStockData(data) {
    return request({
        url:`platform/food/material/check/data`,
        method:'post',
        data
    })
}
//审核盘点
export function auditCheck(data) {
    return request({
        url:`/platform/food/material/check/auditCheck`,
        method:'post',
        data
    })
}
//发起盘点
export function initCheck(data) {
    return request({
        url:`/platform/food/material/check/initCheck`,
        method:'post',
        data
    })
}