<template>
  <el-container class="page-container">
    <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

    <!--</el-header>-->
    <el-main class="page-main" style="padding: 0 20px">
      <br>
      <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="8" :lg="6">
            <el-date-picker
                v-model="query.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                style="width: auto"
            >
            </el-date-picker>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="6">
            <el-form-item label-width="0">
              <el-button type="primary" @click="handleSearch">
                <i class="cw-icon cw-icon-sousuo"></i>搜索
              </el-button>
<!--              <el-button type="primary" plain @click="clearSearch">-->
<!--                <i class="cw-icon cw-icon-zhongzhi"></i>重置-->
<!--              </el-button>-->
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>


      <el-table
          :data="tableData"
          show-summary
          style="width: 100%">
        <el-table-column
            label="订单号"
            prop="tradeno"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="用量"
            prop="cost"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            label="单位"
            prop="unit"
        >
        </el-table-column>
        <el-table-column
            label="支付时间"
            prop="payTime"
            :show-overflow-tooltip="true"
        >
        </el-table-column>

      </el-table>
<!--      <br>-->
<!--      <el-pagination-->
<!--          background-->
<!--          @size-change="handleSizeChange"-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page="pageInfo.pageNumber"-->
<!--          :page-sizes="[10, 20, 30, 40]"-->
<!--          :page-size="pageInfo.pageSize"-->
<!--          layout="total, sizes, prev, pager, next, jumper"-->
<!--          :total="pageInfo.totalCount">-->
<!--      </el-pagination>-->
    </el-main>

  </el-container>

</template>

<script>
import {mapGetters} from 'vuex'
import {
  selectMaterialAndOrder
} from '../../../api/foodManager'
import {initDayTime} from '../../../utils'
import moment from 'moment'
export default {
  name: "used",
  props:{
    materialId:{

    },
  },
  data() {
    return {
      tableData: [],
      pageInfo: {
        totalCount: 0,
        pageNumber: 1,
        pageSize: 10,
        pageOrderName: 'opAt',
        pageOrderBy: 'descending'
      },
      query: {
        time:initDayTime(),
      },
      posData:[]
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),

  },
  watch:{
    materialId(val){
      this.selectMaterialAndOrder()
    }
  },
  methods: {

    handleSearch() {
      this.selectMaterialAndOrder()
    },
    // clearSearch() {
    //   this.query = {
    //     name: null,
    //   },
    //       this.handleSearch()
    // },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val
      this.pageInfo.page = 1
      this.selectFoodShoppingList()
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNumber = val
      this.selectFoodShoppingList()
    },
    async selectMaterialAndOrder(){
      try {
        let obj=!this.query.time?{}:{startTime:this.query.time[0],endTime:this.query.time[1]}
        let query = Object.assign({},{materialId:this.materialId},obj);

        let { data }=await selectMaterialAndOrder(query);
        this.tableData = data.goods_orders;
      }catch (e) {

      }
    }






  },
  mounted() {
    this.selectMaterialAndOrder();
  }
}
</script>

<style lang="less">
.adsdia {
  .el-upload {
    height: 100px;
    width: 100px !important;
    line-height: 100px;
  }
}

.btn-group {
  button {
    margin-left: 20px;
  }

}

.el-input--prefix .el-input__inner {
  padding-left: 46px;
}

.el-input__prefix {
  left: 15px;
  color: #303133;
  i {
    font-style: normal;
  }
}

.icon-cursor {
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    color: #348EED;
  }
}

.avatar-uploader {
  .el-upload {
    border: 1px solid #DCDFE6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 72px;
    display: block;
    &:hover {
      border-color: #409EFF;
    }
  }
  .cw-icon-tupian {
    font-size: 24px;
    color: #c0c4cc;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
  }
  .avatar {
    width: 72px;
    height: 72px;
    display: block;
  }
}
</style>
